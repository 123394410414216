<template>
  <div>
    <v-dialog v-model="dialog" width="500" style="overflow-x: hidden">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text">
          <v-icon>mdi-plus</v-icon>
          {{ $t("add note") }}
        </v-btn>
      </template>
      <v-card style="overflow-x: hidden">
        <v-card-title class="justify-center">
          <h1>{{ $t("add note") }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-autocomplete :dark="$store.state.isDarkMode" outlined dense :label="$t('note type')" :items="typeItems" v-model="note.type"
                item-text="name" item-value="value"></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
              v-model="note.date"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('note address')" v-model="note.title"></v-text-field>
            </v-col>
            <v-col md="12">
              <quill-editor :class="{ 'white': $store.state.isDarkMode }" v-model="note.note" ref="myQuillEditor">
              </quill-editor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" depressed class="white--text" @click="submitNote" :loading="btnLoading">{{ $t("storage")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      config: {
        charCounterCount: false,
        placeholderText: this.$t("write somthing"),
        direction: "rtl",
      },
      btnLoading: false,
      dialog: false,
      dateDialog: false,
      typeItems: [
        {
          name: this.$t("medical"),
          value: "medical",
        },
        {
          name: this.$t("educational"),
          value: "educational",
        },
        {
          name: this.$t("psychological"),
          value: "psychological",
        },
      ],
      note: {
        title: "",
        type: "",
        note: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  props: {
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
  },
  methods: {
    async submitNote() {
      try {
        if (this.idType == "edu-class") {
          this.note.edu_class_id = this.id;
        } else {
          this.note.grade_id = this.id;
        }
        this.btnLoading = true;
        await axios.post("/note", this.note);
        this.$Notifications(
          this.$t('add success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("noteSubmitted");
        this.dialog = false;
        this.note.title = "";
        this.note.type = "";
        this.note.note = "";
        this.note.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      } catch (err) {
      } finally {
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style></style>
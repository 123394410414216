<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="info" v-on="on" v-bind="attrs"> mdi-pencil </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        <h1>{{ $t('edit') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode"  outlined dense label="اسم المذاكرة" v-model="test.name"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-autocomplete :dark="$store.state.isDarkMode" outlined dense label="المادة" :items="getSubjects" v-model="test.subject" item-text="name"
              item-value="id"></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
            v-model="test.date"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense label="العلامة الدنيا" v-model="test.min_mark" type="number"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense label="العلامة العظمى" v-model="test.max_mark" type="number"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ $t('edit')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      dialog: false,
      dateDialog: false,
      subjectsLoading: false,
      test: {
        name: "",
        subject: null,
        min_mark: null,
        max_mark: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  props: {
    recordData: Object,
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      getSubjects: "gradeEducationClassShared/getSubjects",
    }),
  },
  methods: {
    async submit() {
      try {
        this.btnLoading = true;
        let params = {
          name: this.test.name,
          subject_id: this.test.subject.id,
          date: this.test.date,
          min_mark: this.test.min_mark,
          max_mark: this.test.max_mark,
        };
        if (this.idType == "edu-class") {
          params.edu_class_id = this.id;
        } else {
          params.grade_id = this.id;
        }
        await axios.put(`/test/${this.recordData.id}`, params);
        this.$Notifications(
          this.$t('edit success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("Edited");
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.btnLoading = false;
      }
    },
  },
  created() {
    this.test.name = this.recordData.name;
    this.test.subject = this.recordData.subject;
    this.test.date = this.recordData.date;
    this.test.min_mark = this.recordData.min_mark;
    this.test.max_mark = this.recordData.max_mark;
  },
};
</script>

<style></style>
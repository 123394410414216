<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info"> mdi-pencil </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        <h1>{{ $t('edit') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-autocomplete :dark="$store.state.isDarkMode" outlined dense :label="$t('note type')" :items="typeItems" v-model="note.type"
              item-text="name" item-value="value"></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
              v-model="note.date"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('note address')" v-model="note.title"></v-text-field>
          </v-col>
          <v-col md="12">
            <quill-editor :class="{ 'white': $store.state.isDarkMode }" v-model="note.note" ref="myQuillEditor">
            </quill-editor>
            <!-- <froala
              id="edit"
              :tag="'textarea'"
              :config="config"
              v-model="note.note"
            ></froala> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green" depressed class="white--text" @click="submitNote" :loading="btnLoading">{{ $t('edit')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      config: {
        charCounterCount: false,
        placeholderText: "اكتب شيئاً هنا...",
        direction: "rtl",
      },
      btnLoading: false,
      dialog: false,
      dateDialog: false,
      typeItems: [
        {
          name: this.$t("medical"),
          value: "medical",
        },
        {
          name: this.$t("educational"),
          value: "educational",
        },
        {
          name: this.$t("psychological"),
          value: "psychological",
        },
      ],
      note: {
        title: "",
        type: {
          name: "",
          value: "",
        },
        note: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  props: {
    recordData: Object,
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
  },

  methods: {
    async submitNote() {
      try {
        this.btnLoading = true;
        let params = {
          title: this.note.title,
          type: this.note.type.value,
          note: this.note.note,
          date: this.note.date,
        };
        if (this.idType == "edu-class") {
          params.edu_class_id = this.id;
        } else {
          params.grade_id = this.id;
        }
        await axios.put(`/note/${this.recordData.id}`, params);
        this.$Notifications(
          this.$t('edit success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("noteEdited");
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.btnLoading = false;
      }
    },
  },
  created() {
    this.note.title = this.recordData.title;
    this.note.type.value = this.recordData.type;
    if (this.recordData.type == "medical") {
      this.note.type.name = "medical";
    } else if (this.recordData.type == "educational ") {
      this.note.type.name = "educational";
    } else {
      this.note.type.name = "psychological";
    }
    console.log("note fam", this.note);
    this.note.note = this.recordData.note;
    this.note.date = this.recordData.date;
  },
};
</script>

<style></style>